export const logo = ['608 134', `
  <title>coreui react pro</title>
  <g>
    <g style="fill:#61f2bb">
      <path d="M 139.00,77.00
      C 139.00,77.00 139.00,112.00 139.00,112.00
        139.00,112.00 174.00,112.00 174.00,112.00
        174.00,112.00 174.00,77.00 174.00,77.00
        174.00,77.00 139.00,77.00 139.00,77.00 Z"/>
    </g>
    <g style="fill:#00e08c">
        <path d="M 139.00,42.00
      C 139.00,42.00 139.00,77.00 139.00,77.00
        139.00,77.00 174.00,77.00 174.00,77.00
        174.02,76.89 174.00,42.00 174.00,42.00
        174.00,42.00 139.00,42.00 139.00,42.00 Z
      M 176.31,75.33"/>
    </g>
    <g style="fill:#bbffe5">
      <path d="M 104.00,77.00
      C 104.00,77.00 104.00,112.00 104.00,112.00
        104.00,112.00 139.00,112.00 139.00,112.00
        139.01,111.89 139.00,77.00 139.00,77.00
        139.00,77.00 104.00,77.00 104.00,77.00 Z
      M 176.27,75.33"/>
    </g>


    <g style="fill:#3c4b64">
      <g>
        <path d="
          M 239.22,109.94
          C 239.22,113.98 236.91,116.00 232.28,116.00
            232.28,116.00 209.28,116.00 209.28,116.00
            204.69,116.00 202.39,113.98 202.39,109.94
            202.39,109.94 202.39,91.00 202.39,91.00
            202.39,91.00 213.83,91.00 213.83,91.00
            213.83,91.00 213.83,105.00 213.83,105.00
            213.83,105.00 227.78,105.00 227.78,105.00
            227.78,105.00 227.78,92.00 227.78,92.00
            227.78,92.00 204.88,70.03 204.88,70.03
            203.22,68.42 202.39,66.36 202.39,63.84
            202.39,63.84 202.39,46.06 202.39,46.06
            202.39,42.02 204.69,40.00 209.28,40.00
            209.28,40.00 232.28,40.00 232.28,40.00
            236.91,40.00 239.22,41.99 239.22,45.97
            239.22,45.97 239.22,64.00 239.22,64.00
            239.22,64.00 227.78,64.00 227.78,64.00
            227.78,64.00 227.78,51.00 227.78,51.00
            227.78,51.00 213.83,51.00 213.83,51.00
            213.83,51.00 213.83,63.50 213.83,63.50
            213.83,63.50 236.73,85.38 236.73,85.38
            238.39,86.96 239.22,89.05 239.22,91.66
            239.22,91.66 239.22,109.94 239.22,109.94 Z
          M 280.81,116.00
          C 280.81,116.00 269.69,116.00 269.69,116.00
            269.69,116.00 269.69,114.00 269.69,114.00
            267.11,114.43 264.52,114.85 261.92,115.27
            258.77,115.76 256.16,116.00 254.11,116.00
            250.04,116.00 248.00,113.98 248.00,109.94
            248.00,109.94 248.00,67.00 248.00,67.00
            248.00,67.00 259.09,67.00 259.09,67.00
            259.09,67.00 259.09,107.00 259.09,107.00
            259.09,107.00 269.69,105.00 269.69,105.00
            269.69,105.00 269.69,67.00 269.69,67.00
            269.69,67.00 280.81,67.00 280.81,67.00
            280.81,67.00 280.81,116.00 280.81,116.00 Z
          M 321.92,109.94
          C 321.92,111.84 320.99,113.32 319.12,114.39
            317.27,115.46 314.80,116.00 311.70,116.00
            311.70,116.00 289.00,116.00 289.00,116.00
            289.00,116.00 289.00,40.00 289.00,40.00
            289.00,40.00 300.19,40.00 300.19,40.00
            300.19,40.00 300.19,69.00 300.19,69.00
            302.79,68.62 305.39,68.21 308.00,67.78
            311.29,67.26 313.89,67.00 315.81,67.00
            319.88,67.00 321.92,69.02 321.92,73.06
            321.92,73.06 321.92,109.94 321.92,109.94 Z
          M 310.69,103.25
          C 310.69,103.25 310.69,76.00 310.69,76.00
            310.69,76.00 300.19,77.00 300.19,77.00
            300.19,77.00 300.19,107.00 300.19,107.00
            300.19,107.00 307.12,107.00 307.12,107.00
            309.50,107.00 310.69,105.75 310.69,103.25 Z
          M 361.02,109.94
          C 361.02,113.98 358.95,116.00 354.81,116.00
            354.81,116.00 336.02,116.00 336.02,116.00
            331.88,116.00 329.81,113.98 329.81,109.94
            329.81,109.94 329.81,99.00 329.81,99.00
            329.81,99.00 340.50,99.00 340.50,99.00
            340.50,99.00 340.50,107.00 340.50,107.00
            340.50,107.00 350.31,107.00 350.31,107.00
            350.31,107.00 350.31,99.50 350.31,99.50
            350.31,99.50 333.19,89.78 333.19,89.78
            330.94,88.49 329.81,86.77 329.81,84.61
            329.81,84.61 329.81,73.06 329.81,73.06
            329.81,69.02 331.91,67.00 336.11,67.00
            336.11,67.00 354.72,67.00 354.72,67.00
            358.84,67.00 360.91,69.02 360.91,73.06
            360.91,73.06 360.91,83.00 360.91,83.00
            360.91,83.00 350.31,83.00 350.31,83.00
            350.31,83.00 350.31,76.00 350.31,76.00
            350.31,76.00 340.50,76.00 340.50,76.00
            340.50,76.00 340.50,82.50 340.50,82.50
            340.50,82.50 357.59,92.14 357.59,92.14
            359.88,93.39 361.02,95.15 361.02,97.41
            361.02,97.41 361.02,109.94 361.02,109.94 Z
          M 400.89,109.94
          C 400.89,113.98 398.82,116.00 394.69,116.00
            394.69,116.00 375.30,116.00 375.30,116.00
            371.10,116.00 369.00,113.98 369.00,109.94
            369.00,109.94 369.00,73.06 369.00,73.06
            369.00,69.02 371.10,67.00 375.30,67.00
            375.30,67.00 394.69,67.00 394.69,67.00
            398.82,67.00 400.89,69.02 400.89,73.06
            400.89,73.06 400.89,85.00 400.89,85.00
            400.89,85.00 390.00,85.00 390.00,85.00
            390.00,85.00 390.00,76.00 390.00,76.00
            390.00,76.00 379.98,76.00 379.98,76.00
            379.98,76.00 379.98,107.00 379.98,107.00
            379.98,107.00 390.00,107.00 390.00,107.00
            390.00,107.00 390.00,98.00 390.00,98.00
            390.00,98.00 400.89,98.00 400.89,98.00
            400.89,98.00 400.89,109.94 400.89,109.94 Z
          M 439.62,116.00
          C 439.62,116.00 409.59,116.00 409.59,116.00
            409.59,116.00 409.59,40.00 409.59,40.00
            409.59,40.00 421.02,40.00 421.02,40.00
            421.02,40.00 421.02,105.00 421.02,105.00
            421.02,105.00 439.62,105.00 439.62,105.00
            439.62,105.00 439.62,116.00 439.62,116.00 Z
          M 457.38,60.00
          C 457.38,60.00 446.20,60.00 446.20,60.00
            446.20,60.00 446.20,48.00 446.20,48.00
            446.20,48.00 457.38,48.00 457.38,48.00
            457.38,48.00 457.38,60.00 457.38,60.00 Z
          M 457.28,116.00
          C 457.28,116.00 446.30,116.00 446.30,116.00
            446.30,116.00 446.30,67.00 446.30,67.00
            446.30,67.00 457.28,67.00 457.28,67.00
            457.28,67.00 457.28,116.00 457.28,116.00 Z
          M 498.92,116.00
          C 498.92,116.00 487.69,116.00 487.69,116.00
            487.69,116.00 487.69,76.00 487.69,76.00
            487.69,76.00 477.19,77.50 477.19,77.50
            477.19,77.50 477.19,116.00 477.19,116.00
            477.19,116.00 466.00,116.00 466.00,116.00
            466.00,116.00 466.00,67.00 466.00,67.00
            466.00,67.00 477.19,67.00 477.19,67.00
            477.19,67.00 477.19,69.50 477.19,69.50
            479.79,68.97 482.38,68.46 484.95,67.97
            488.14,67.32 490.76,67.00 492.81,67.00
            496.88,67.00 498.92,69.02 498.92,73.06
            498.92,73.06 498.92,116.00 498.92,116.00 Z
          M 538.89,109.94
          C 538.89,113.98 536.79,116.00 532.59,116.00
            532.59,116.00 513.30,116.00 513.30,116.00
            509.10,116.00 507.00,113.98 507.00,109.94
            507.00,109.94 507.00,73.06 507.00,73.06
            507.00,69.02 509.10,67.00 513.30,67.00
            513.30,67.00 532.59,67.00 532.59,67.00
            536.79,67.00 538.89,69.02 538.89,73.06
            538.89,73.06 538.89,90.92 538.89,90.92
            538.89,90.92 535.81,94.00 535.81,94.00
            535.81,94.00 517.70,94.00 517.70,94.00
            517.70,94.00 517.70,107.00 517.70,107.00
            517.70,107.00 528.20,107.00 528.20,107.00
            528.20,107.00 528.20,100.00 528.20,100.00
            528.20,100.00 538.89,100.00 538.89,100.00
            538.89,100.00 538.89,109.94 538.89,109.94 Z
          M 528.20,87.14
          C 528.20,87.14 528.20,76.00 528.20,76.00
            528.20,76.00 517.70,76.00 517.70,76.00
            517.70,76.00 517.70,87.14 517.70,87.14
            517.70,87.14 528.20,87.14 528.20,87.14 Z
        "/>
      </g>
    </g>
  </g>
`]
