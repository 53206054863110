export const logoNegative = ['608 134', `
  <title>subscline logo</title>
  <g>
    <g style="fill:#38d69a;">
      <g>
        <path d="M 69.91,75.45
        C 69.91,78.48 68.17,80.00 64.70,80.00
          64.70,80.00 47.45,80.00 47.45,80.00
          44.02,80.00 42.30,78.48 42.30,75.45
          42.30,75.45 42.30,62.00 42.30,62.00
          42.30,62.00 50.86,62.00 50.86,62.00
          50.86,62.00 50.86,72.00 50.86,72.00
          50.86,72.00 61.34,72.00 61.34,72.00
          61.34,72.00 61.34,62.00 61.34,62.00
          61.34,62.00 44.17,45.38 44.17,45.38
          42.92,44.15 42.30,42.59 42.30,40.69
          42.30,40.69 42.30,27.55 42.30,27.55
          42.30,24.52 44.02,23.00 47.45,23.00
          47.45,23.00 64.70,23.00 64.70,23.00
          68.17,23.00 69.91,24.49 69.91,27.47
          69.91,27.47 69.91,41.00 69.91,41.00
          69.91,41.00 61.34,41.00 61.34,41.00
          61.34,41.00 61.34,31.00 61.34,31.00
          61.34,31.00 50.86,31.00 50.86,31.00
          50.86,31.00 50.86,40.50 50.86,40.50
          50.86,40.50 68.03,57.06 68.03,57.06
          69.28,58.26 69.91,59.84 69.91,61.81
          69.91,61.81 69.91,75.45 69.91,75.45 Z
        M 100.61,80.00
        C 100.61,80.00 92.27,80.00 92.27,80.00
          92.27,80.00 92.27,78.00 92.27,78.00
          90.34,78.43 88.39,78.85 86.44,79.27
          84.07,79.76 82.12,80.00 80.58,80.00
          77.52,80.00 76.00,78.48 76.00,75.45
          76.00,75.45 76.00,43.00 76.00,43.00
          76.00,43.00 84.31,43.00 84.31,43.00
          84.31,43.00 84.31,73.00 84.31,73.00
          84.31,73.00 92.27,71.00 92.27,71.00
          92.27,71.00 92.27,43.00 92.27,43.00
          92.27,43.00 100.61,43.00 100.61,43.00
          100.61,43.00 100.61,80.00 100.61,80.00 Z
        M 131.69,75.45
        C 131.69,76.87 130.99,77.98 129.59,78.80
          128.21,79.60 126.35,80.00 124.03,80.00
          124.03,80.00 107.00,80.00 107.00,80.00
          107.00,80.00 107.00,23.00 107.00,23.00
          107.00,23.00 115.39,23.00 115.39,23.00
          115.39,23.00 115.39,45.00 115.39,45.00
          117.35,44.62 119.30,44.21 121.25,43.78
          123.72,43.26 125.67,43.00 127.11,43.00
          130.16,43.00 131.69,44.52 131.69,47.55
          131.69,47.55 131.69,75.45 131.69,75.45 Z
        M 123.27,70.22
        C 123.27,70.22 123.27,50.00 123.27,50.00
          123.27,50.00 115.39,52.00 115.39,52.00
          115.39,52.00 115.39,73.00 115.39,73.00
          115.39,73.00 120.59,73.00 120.59,73.00
          122.38,73.00 123.27,72.07 123.27,70.22 Z
        M 161.25,75.45
        C 161.25,78.48 159.70,80.00 156.61,80.00
          156.61,80.00 142.50,80.00 142.50,80.00
          139.41,80.00 137.86,78.48 137.86,75.45
          137.86,75.45 137.86,67.00 137.86,67.00
          137.86,67.00 145.88,67.00 145.88,67.00
          145.88,67.00 145.88,73.00 145.88,73.00
          145.88,73.00 153.23,73.00 153.23,73.00
          153.23,73.00 153.23,67.50 153.23,67.50
          153.23,67.50 140.39,60.05 140.39,60.05
          138.70,59.06 137.86,57.73 137.86,56.08
          137.86,56.08 137.86,47.55 137.86,47.55
          137.86,44.52 139.43,43.00 142.58,43.00
          142.58,43.00 156.53,43.00 156.53,43.00
          159.63,43.00 161.19,44.52 161.19,47.55
          161.19,47.55 161.19,55.00 161.19,55.00
          161.19,55.00 153.23,55.00 153.23,55.00
          153.23,55.00 153.23,50.00 153.23,50.00
          153.23,50.00 145.88,50.00 145.88,50.00
          145.88,50.00 145.88,54.50 145.88,54.50
          145.88,54.50 158.69,61.89 158.69,61.89
          160.39,62.85 161.25,64.20 161.25,65.92
          161.25,65.92 161.25,75.45 161.25,75.45 Z
        M 190.92,75.45
        C 190.92,78.48 189.37,80.00 186.27,80.00
          186.27,80.00 171.73,80.00 171.73,80.00
          168.58,80.00 167.00,78.48 167.00,75.45
          167.00,75.45 167.00,47.55 167.00,47.55
          167.00,44.52 168.58,43.00 171.73,43.00
          171.73,43.00 186.27,43.00 186.27,43.00
          189.37,43.00 190.92,44.52 190.92,47.55
          190.92,47.55 190.92,56.00 190.92,56.00
          190.92,56.00 182.75,56.00 182.75,56.00
          182.75,56.00 182.75,50.00 182.75,50.00
          182.75,50.00 175.25,50.00 175.25,50.00
          175.25,50.00 175.25,73.00 175.25,73.00
          175.25,73.00 182.75,73.00 182.75,73.00
          182.75,73.00 182.75,66.00 182.75,66.00
          182.75,66.00 190.92,66.00 190.92,66.00
          190.92,66.00 190.92,75.45 190.92,75.45 Z
        M 219.97,80.00
        C 219.97,80.00 197.44,80.00 197.44,80.00
          197.44,80.00 197.44,23.00 197.44,23.00
          197.44,23.00 206.02,23.00 206.02,23.00
          206.02,23.00 206.02,72.00 206.02,72.00
          206.02,72.00 219.97,72.00 219.97,72.00
          219.97,72.00 219.97,80.00 219.97,80.00 Z
        M 233.53,38.00
        C 233.53,38.00 225.16,38.00 225.16,38.00
          225.16,38.00 225.16,29.00 225.16,29.00
          225.16,29.00 233.53,29.00 233.53,29.00
          233.53,29.00 233.53,38.00 233.53,38.00 Z
        M 233.47,80.00
        C 233.47,80.00 225.22,80.00 225.22,80.00
          225.22,80.00 225.22,43.00 225.22,43.00
          225.22,43.00 233.47,43.00 233.47,43.00
          233.47,43.00 233.47,80.00 233.47,80.00 Z
        M 264.69,80.00
        C 264.69,80.00 256.27,80.00 256.27,80.00
          256.27,80.00 256.27,50.00 256.27,50.00
          256.27,50.00 248.39,52.50 248.39,52.50
          248.39,52.50 248.39,80.00 248.39,80.00
          248.39,80.00 240.00,80.00 240.00,80.00
          240.00,80.00 240.00,43.00 240.00,43.00
          240.00,43.00 248.39,43.00 248.39,43.00
          248.39,43.00 248.39,45.50 248.39,45.50
          250.35,44.97 252.29,44.46 254.22,43.97
          256.60,43.32 258.57,43.00 260.11,43.00
          263.16,43.00 264.69,44.52 264.69,47.55
          264.69,47.55 264.69,80.00 264.69,80.00 Z
        M 294.92,75.45
        C 294.92,78.48 293.34,80.00 290.19,80.00
          290.19,80.00 275.73,80.00 275.73,80.00
          272.58,80.00 271.00,78.48 271.00,75.45
          271.00,75.45 271.00,47.55 271.00,47.55
          271.00,44.52 272.58,43.00 275.73,43.00
          275.73,43.00 290.19,43.00 290.19,43.00
          293.34,43.00 294.92,44.52 294.92,47.55
          294.92,47.55 294.92,60.69 294.92,60.69
          294.92,60.69 292.61,63.00 292.61,63.00
          292.61,63.00 279.02,63.00 279.02,63.00
          279.02,63.00 279.02,73.00 279.02,73.00
          279.02,73.00 286.89,73.00 286.89,73.00
          286.89,73.00 286.89,67.00 286.89,67.00
          286.89,67.00 294.92,67.00 294.92,67.00
          294.92,67.00 294.92,75.45 294.92,75.45 Z
        M 286.89,58.05
        C 286.89,58.05 286.89,50.00 286.89,50.00
          286.89,50.00 279.02,50.00 279.02,50.00
          279.02,50.00 279.02,58.05 279.02,58.05
          279.02,58.05 286.89,58.05 286.89,58.05 Z"/>
      </g>
    </g>
  </g>
`]
