export const sygnet = ['160 160', `
  <title>coreui logo</title>
  <g>
    <g style="fill:#61f2bb;">
      <path d="M 77.00,83.00
      C 77.00,83.00 77.00,137.00 77.00,137.00
        77.00,137.00 131.00,137.00 131.00,137.00
        131.00,137.00 131.00,83.00 131.00,83.00
        131.00,83.00 77.00,83.00 77.00,83.00 Z"/>
    </g>
    <g style="fill:#00e08c;">
      <path d="M 77.00,29.00
      C 77.00,29.00 77.00,83.00 77.00,83.00
        77.00,83.00 131.00,83.00 131.00,83.00
        131.00,83.00 131.00,29.00 131.00,29.00
        131.00,29.00 77.00,29.00 77.00,29.00 Z"/>
    </g>
    <g style="fill:#bbffe5;">
      <path d="M 23.00,83.00
      C 23.00,83.00 23.00,137.00 23.00,137.00
        23.00,137.00 77.00,137.00 77.00,137.00
        77.00,137.00 77.00,83.00 77.00,83.00
        77.00,83.00 23.00,83.00 23.00,83.00 Z"/>
      </g>
  </g>
`]
